.loaderContainer {
  position: relative;
  margin: 1rem auto;
  height: 200px;
  width: 200px;
  display: flex;
  align-content: center;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.loaderContainer h4 {
  color: black;
}
.loaderContainer svg {
  position: absolute;
}

.spinner {
  animation: rotator 5s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 622;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: #375f61;
  animation: dash 5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 622;
  }
  50% {
    stroke-dashoffset: 155.5;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 622;
    transform: rotate(450deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loaderContainer h4 {
  animation: fadeIn 1s ease-in infinite alternate;
}
