@font-face {
  font-family: "San Francisco";
  font-weight: 100;
  src: url("https://storage.googleapis.com/pifa.se/fonts/sanfranciscodisplay-ultralight-webfont.woff");
}
/** Thin */
@font-face {
  font-family: "San Francisco";
  font-weight: 200;
  src: url("https://storage.googleapis.com/pifa.se/fonts/sanfranciscodisplay-thin-webfont.woff");
}
/** Regular */
@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("https://storage.googleapis.com/pifa.se/fonts/sanfranciscodisplay-regular-webfont.woff");
}
/** Medium */
@font-face {
  font-family: "San Francisco";
  font-weight: 500;
  src: url("https://storage.googleapis.com/pifa.se/fonts/sanfranciscodisplay-medium-webfont.woff");
}
/** Semi Bold */
@font-face {
  font-family: "San Francisco";
  font-weight: 600;
  src: url("https://storage.googleapis.com/pifa.se/fonts/sanfranciscodisplay-semibold-webfont.woff");
}
/** Bold */
@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("https://storage.googleapis.com/pifa.se/fonts/sanfranciscodisplay-bold-webfont.woff");
}
html,
body,
#root,
.app,
.content {
  width: 100%;
  min-height: 100vh;
}
* {
  font-family: "sfregular", Roboto, "Titillium Web", Arial, -apple-system,
    Helvetica, "Times New Roman", serif, sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
