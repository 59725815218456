body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}
.login-background-sol {
  background-image: url("https://storage.googleapis.com/pifa.se/companies/6617c990824414844797191b/sol.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-origin: content-box;
  height: 100vh;
}
.login-background {
  background-image: url("https://storage.googleapis.com/pifa.se/AdobeStock_592027801.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-origin: content-box;
  height: 100vh;
}

.login-container {
  width: 90vw;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  height: 100vh;
  margin: 0 auto;
}
.login-card-sol {
  width: 550px;
  z-index: 1000;
  padding: 1rem;
  position: relative;
}
.login-card {
  width: 450px;
  z-index: 1000;
  padding: 1rem;
  position: relative;
}
.login-button {
  color: white;
  border-radius: 5px;
}

.login-button:hover {
  color: white;
  border-radius: 40px;
}
.landing-page-title-mobile {
  display: none;
}
@media screen and (max-device-width: 700px) {
  .login-background {
    background-image: url("https://storage.googleapis.com/pifa.se/AdobeStock_592027801.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: content-box;
    height: 100vh;
    text-align: center;
  }

  .login-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    text-align: center;
    height: 100vh;
  }

  .login-card {
    z-index: 1000;
  }
  .app-logo {
    margin: 0 auto;
  }
  .create-order-link {
    margin: 0;
    padding: 0;
  }
  .landing-page-title {
    display: none;
  }
  .login-button-landing-page {
    display: none;
  }
  .login-link-landing-page-mobile {
    display: inline;
  }
  .login-link-landing-page {
    display: none;
  }
  .landing-page-title-mobile {
    display: inline;
  }
  .login-button-landing-page-mobile {
    display: inline;
    z-index: 100;
    text-transform: none;
    margin-right: 100px;
    background: linear-gradient(
      90deg,
      rgba(90, 255, 21, 1) 0%,
      rgba(0, 183, 18, 1) 35%
    );
    border-radius: 5px;
    padding: 10px 0;
    border: solid black 1px;
    color: white;
    font-weight: 500;
  }
}
@media screen and (max-device-width: 1100px) {
  .mobile-link-menu {
    display: block;
  }
  .mobile-settings-menu {
    display: block;
  }
  .nav-item {
    display: none;
  }
  .create-order-link {
    display: none;
  }
  .desktop-settings-menu {
    display: none;
  }
}
.create-order-link {
  margin: 0 1rem;
}
.landing-page-title {
  color: black;
  font-size: 2rem;
  margin: auto 0;
  text-decoration: none;
}
.login-button-landing-page {
  z-index: 100;
  width: 150px;
  text-transform: none;
  background: linear-gradient(
    90deg,
    rgba(90, 255, 21, 1) 0%,
    rgba(0, 183, 18, 1) 35%
  );
  border-radius: 5px;
  border: solid black 1px;
  color: white;
  font-weight: 500;
}
.login-button-landing-page-mobile {
  display: none;
}
.login-button-landing-page:hover {
  border-radius: 50px;
  border: solid white 1px;
}
.login-link-landing-page-mobile {
  display: none;
}
.login-link-landing-page {
  display: inline-flex;
}
.create-order {
  border-radius: 5px;
  border: solid black 1px;
  color: black;
  font-weight: 500;
}
.create-order:hover {
  border-radius: 50px;
}

.nav-item a {
  position: relative;
  color: black;
  text-decoration: none;
}
.nav-item:hover {
  cursor: pointer;
}
.nav-link {
  font-size: 0.9rem;
}
.nav-item > a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.nav-item > a:before {
  content: "";
  position: absolute;
  width: 100%;
  margin-top: 1rem;
  height: 2px;
  bottom: 0;
  left: 0;
  background: black;
  visibility: hidden;
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.logo {
  margin: 0 2rem 0 6rem;
}
.myAvatar {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .myLinks {
    display: none;
  }
  .myAvatar {
    display: inline;
  }
  .navItem .myLinks {
    display: none;
  }
  .logo {
    margin: 0 1rem;
  }
}
@media only screen and (max-width: 1600px) {
  .desktop-settings-menu {
    display: none;
  }
  .mobile-settings-menu {
    display: inline;
  }
}
.mainMenu {
  color: white;
}
.mainMenu:hover {
  background: lightgray;
  color: black;
}
.articleCentralLink {
}
.articleCentralLink:hover {
  font-weight: bold;
}
.customerCentralLink {
}
.customerCentralLink:hover {
  font-weight: bold;
}
