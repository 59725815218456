/** Ultra Light */
@font-face {
  font-family: "sflight";
  src: url("./SFUIText-Light.ttf") format("truetype");
}
/** Thin */
@font-face {
  font-family: "sfregular";
  src: url("./SFUIText-Regular.ttf") format("truetype");
}
/** Regular */
@font-face {
  font-family: "sfmedium";
  src: url("./SFUIText-Medium.ttf") format("truetype");
}
/** Medium */
@font-face {
  font-family: "sfsemibold";
  src: url("./SFUIText-Semibold.ttf") format("truetype");
}
/** Semi Bold */
@font-face {
  font-family: "sfbold";
  src: url("./SFUIText-Bold.ttf") format("truetype");
}
/** Bold */
@font-face {
  font-family: "sfheavy";
  src: url("./SFUIText-Heavy.ttf") format("truetype");
}
